import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getStudentsData = createAsyncThunk(
  'studentPerformance/getStudentsData',
  async (studentEnrollmentIds) => {
    const response = await api.post('student-enrollment/students', {
      studentEnrollmentIds,
    });
    const { data } = await response.data;

    return data;
  }
);

export const createStudentPerformance = createAsyncThunk(
  'studentPerformance/createStudentPerformance',
  async (studentEnrollment) => {
    const date = new Date().toISOString();
    const [splitedDate] = date.split('.');
    api.get(`report/student-enrollment/${studentEnrollment.id}`, {
      params: {
        fileNameTag: splitedDate,
      },
    });

    const fileNameTemp = `boletim-escolar_${studentEnrollment.id}_${splitedDate}~`;

    return {
      name: fileNameTemp,
      id: splitedDate,
      studentEnrollment,
    };
  }
);

export const createOverviewMap = createAsyncThunk(
  'studentPerformance/createOverviewMap',
  async (lessonPlan) => {
    const date = new Date().toISOString();
    const [splitedDate] = date.split('.');
    api.get(`report/lesson-plan/${lessonPlan.id}/overview-map`, {
      params: {
        fileNameTag: splitedDate,
        lessonPlanId: lessonPlan.id,
      },
    });

    const fileNameTemp = `mapa-geral_${lessonPlan.id}_${splitedDate}~`;

    return {
      name: fileNameTemp,
      id: splitedDate,
      lessonPlan,
    };
  }
);

export const getStudentPerformance = createAsyncThunk(
  'studentPerformance/getStudentPerformance',
  async (_, { dispatch }) => {
    const response = await api.get('file');
    const { data } = await response.data;

    const studentEnrollmentIds = [];

    data.forEach(({ name }) => {
      const [fileName, id] = name.split('_');
      if (
        fileName === 'boletim-escolar' &&
        !studentEnrollmentIds.includes(id)
      ) {
        studentEnrollmentIds.push(id);
      }
    });
    await dispatch(getStudentsData(studentEnrollmentIds));

    return data.map((report) => {
      const [, , endFileName] = report.name.split('_');
      const [date] = endFileName.split('.');
      return { ...report, id: date };
    });
  }
);

export const checkReport = createAsyncThunk(
  'studentPerformance/checkReport',
  async (_, { dispatch, getState }) => {
    const {
      studentPerformance: { entities },
    } = getState();
    const reports = Object.values(entities);

    const isGenerating = reports.some(({ name }) => {
      const lastPosition = name[name.length - 1];
      return lastPosition === '~';
    });

    if (isGenerating) {
      const response = await api.get('file');
      const { data } = await response.data;

      const studentEnrollmentIds = [];

      data.forEach(({ name }) => {
        const [fileName, id] = name.split('_');
        if (
          fileName === 'boletim-escolar' &&
          !studentEnrollmentIds.includes(id)
        ) {
          studentEnrollmentIds.push(id);
        }
      });
      await dispatch(getStudentsData(studentEnrollmentIds));

      return data.map((report) => {
        const [, , endFileName] = report.name.split('_');
        const [date] = endFileName.split('.');
        return { ...report, id: date };
      });
    }

    return null;
  }
);

const studentPerformanceAdapter = createEntityAdapter({});

export const { selectAll: selectStudentPerformances } =
  studentPerformanceAdapter.getSelectors((state) => state.studentPerformance);

const studentPerformanceSlice = createSlice({
  name: 'studentPerformance',
  initialState: studentPerformanceAdapter.getInitialState({
    studentsData: [],
  }),
  reducers: {},
  extraReducers: {
    [getStudentPerformance.fulfilled]: (state, { payload }) => {
      studentPerformanceAdapter.setAll(state, payload);
    },

    [checkReport.fulfilled]: (state, { payload }) => {
      if (payload) {
        const updates = payload.map((item) => ({
          id: item.id,
          changes: item,
        }));

        studentPerformanceAdapter.updateMany(state, updates);
      }
    },

    [getStudentsData.fulfilled]: (state, { payload }) => {
      state.studentsData = payload;
    },

    [createStudentPerformance.fulfilled]: (state, { payload }) => {
      state.studentsData = [...state.studentsData, payload.studentEnrollment];

      studentPerformanceAdapter.addOne(state, payload);

      const { id } = payload;
      state.ids = [id, ...state.ids.filter((existingId) => existingId !== id)];
    },

    [createOverviewMap.fulfilled]: (state, { payload }) => {
      state.studentsData = [...state.studentsData, payload.studentEnrollment];

      studentPerformanceAdapter.addOne(state, payload);

      const { id } = payload;
      state.ids = [id, ...state.ids.filter((existingId) => existingId !== id)];
    },
  },
});

export default studentPerformanceSlice.reducer;
