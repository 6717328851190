import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const report = {
  overviewDemonstrative: lazy(() =>
    import('views/report/overviewDemonstrative/OverviewDemonstrativePage')
  ),
  studentPerformance: lazy(() =>
    import('views/report/studentPerformance/StudentPerformancePage')
  ),
  schoolHistory: lazy(() =>
    import('views/report/schoolHistory/SchoolHistoryPage')
  ),
};
const appRoot = DEFAULT_PATHS.APP.endsWith('/')
  ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length)
  : DEFAULT_PATHS.APP;

const reportRoute = {
  path: `${appRoot}/relatorio`,
  exact: true,
  icon: 'arrow-end-bottom',
  label: 'commons.reports',
  protected: true,
  roles: [USER_ROLE.Admin],
  to: `${appRoot}/relatorio`,
  subs: [
    {
      path: '/demonstrativo',
      label: 'report.overview-demonstrative',
      component: report.overviewDemonstrative,
      exact: true,
      smeljOnly: true,
    },
    {
      path: '/boletim',
      label: 'commons.students',
      component: report.studentPerformance,
      exact: true,
      schoolOnly: true,
    },
    {
      path: '/historico-escolar',
      label: 'report.school-history',
      component: report.schoolHistory,
      exact: true,
      schoolOnly: true,
    },
  ],
};

export default reportRoute;
