const commonsPtMessages = {
  'commons.able': 'Habilitado',
  'commons.absent': 'Ausente',
  'commons.absence': 'Ausente',
  'commons.academic-period': 'Período acadêmico',
  'commons.academicTermType': 'Tipo do Período Acadêmico',
  'commons.action': 'Ação',
  'commons.actions': 'Ações',
  'commons.activate': 'Ativar',
  'commons.active': 'Ativo',
  'commons.add': 'Adicionar',
  'commons.address': 'Endereço',
  'commons.addStudent': 'Adicionar Aluno',
  'commons.admin': 'Administração',
  'commons.already-registered': 'Já cadastrado',
  'commons.apply': 'Aplicar',
  'commons.archiev': 'Arquivar',
  'commons.archive': 'Arquivar',
  'commons.assessment': 'Parecer',
  'commons.attendance': 'Presente',

  'commons.basicEducation': 'Educação Básica',
  'commons.being': 'Presente',
  'commons.birth': 'Nascimento',
  'commons.block': 'Bloquear',
  'commons.breed': 'Raça',

  'commons.calendar': 'Calendário',
  'commons.cancel': 'Cancelar',
  'commons.cellphone': 'Celular',
  'commons.change-date': 'Trocar data',
  'commons.class': 'Turma',
  'commons.classes': 'Turmas',
  'commons.classDevelopment': 'Desenvolvimento da Aula',
  'commons.classesHeld': 'Aulas já realizadas',
  'commons.close': 'Fechar',
  'commons.componentCurriculum': 'Componente Curricular',
  'commons.componentsCurriculum': 'Componente Curriculares',
  'commons.compose': 'Compor',
  'commons.confirm': 'Confirmar',
  'commons.confirm-delete-text':
    'Deseja prosseguir com a exclusão? Clique no botão abaixo para confirmar.',
  'commons.confirmed': 'Confirmado',
  'commons.confirmClick': 'Clique novamente para confirmar',
  'commons.create-lesson': 'Criar aula',
  'commons.create-report': 'Criar relatório',
  'commons.curriculum-component': 'Componente Curricular',
  'commons.curriculumMatrix': 'Matriz Curricular',

  'commons.dataNotFound': 'Dados não encontrados!',
  'commons.date': 'Data',
  'commons.date-interval': '{start} a {end}',
  'commons.date-range': 'Intervalo de datas',
  'commons.day': 'Dia',
  'commons.delete': 'Excluir',
  'commons.deleting': 'Excluindo',
  'commons.delete-success': 'Dado excluído com sucesso',
  'commons.description': 'Descrição',
  'commons.details': 'Detalhes',
  'commons.didati-team': 'Equipe Didati',
  'commons.documents': 'Documentos',
  'commons.done': 'Finalizar',

  'commons.edit': 'Editar',
  'commons.edit-event': 'Editar Evento',
  'commons.edit-frequency': 'Editar frequências',
  'commons.edit-profile': 'Editar Perfil',
  'commons.educationLevel': 'Nível de ensino',
  'commons.education-level': 'Nível de Ensino',
  'commons.end': 'Fim',
  'commons.end-date': 'Data final',
  'commons.enroll': 'Inscrever-se',
  'commons.enroll-success-message': 'Inscrição realizada com sucesso.',
  'commons.enroll-status': 'Status da Inscrição',
  'commons.emptyDataFilterSelection':
    'Selecione o {year} e {educationLevel} para buscar as Séries',
  'commons.empty-search-text':
    'Desculpe :( Não encontramos nenhuma informação, mas você pode tentar pesquisar com outras palavras.',
  'commons.errorMessage': 'Ocorreu um erro! Tente novamente',
  'commons.evaluation': 'Avaliação',
  'commons.evaluations': 'Avaliações',
  'commons.evaluation-type': 'Tipo de Avaliação',
  'commons.events': 'Eventos',

  'commons.father': 'Pai',
  'commons.fill.all.fields': 'Preencha todos os campos para continuar',
  'commons.filter': 'Filtrar',
  'commons.frequency': 'Frequência',
  'commons.fullName': 'Nome Completo',

  'commons.generate-report': 'Gerar Relatório',
  'commons.generating': 'Gerando',
  'commons.grade': 'Série',
  'commons.grades': 'Notas',

  'commons.hello': 'Olá!',
  'commons.history': 'Histórico',
  'commons.home': 'Início',
  'commons.hour': 'Horas',
  'commons.highest': 'Nota Máxima Anual',
  'commons.highest-mark': 'Nota Máxima',
  'commons.help': 'Ajuda',

  'commons.id': 'ID',
  'commons.in': 'De',
  'commons.inactive': 'Inativo',
  'commons.infoBasic': 'Informações Básicas',
  'commons.informationNotFound': 'Nenhuma informação encontrada',
  'commons.initials': 'Sigla',
  'commons.instructor': 'Professor',
  'commons.instructors': 'Professores',
  'commons.issue': 'Emissão',
  'commons.issue-date': 'Data de emissão',
  'commons.item': 'Item',
  'commons.items': 'Itens',

  'commons.justification': 'Justificativa',
  'commons.justified': 'Justificado',

  'commons.knowledgeArea': 'Área do Conhecimento',
  'commons.knowledge-area': 'Área do Conhecimento',

  'commons.launch-lesson': 'Lançar aula',
  'commons.lesson': 'Aula',
  'commons.lesson-plan': 'Plano de Aula',
  'commons.lesson-plans': 'Planos de Aula',
  'commons.lessons': 'Aulas',
  'commons.lets-start': 'Vamos começar?',
  'commons.loadingWithDots': 'Carregando...',
  'commons.loadMore': 'Carregar mais...',
  'commons.paginationShowing':
    'Exibindo {countRows} de {totalRowCount} {totalRowCount, plural, =0 {{itemNameSingular}} one {{itemNameSingular}} other {{itemNamePlural}}}',
  'commons.logout': 'Sair',

  'commons.manage': 'Gerenciar',
  'commons.mandatory': 'Obrigatório',
  'commons.mark': 'Nota',
  'commons.max-occupation': 'Ocupação Máxima',
  'commons.message': 'Mensagem',
  'commons.month': 'Mês',
  'commons.mother': 'Mãe',
  'commons.move': 'Mover',
  'commons.myData': 'Meus dados',

  'commons.name': 'Nome',
  'commons.navigate': 'Navegar',
  'commons.new-event': 'Novo Evento',
  'commons.newStudent': 'Adicionar novo aluno',
  'commons.next': 'Próximo',
  'commons.no': 'Não',
  'commons.noOptions': 'Sem opções',
  'commons.notDefined': 'Não Definido',
  'commons.note-summary-table': 'Quadro Resumo de Notas',
  'commons.note-summary': 'Resumo de Notas',
  'commons.number': 'Número',
  'commons.note': 'Nota',
  'commons.no-connection': 'Sem conexão',

  'commons.observation': 'Observação',
  'commons.observations': 'Observações',
  'commons.optional': 'Opcional',
  'commons.overview-map-report': 'Relatório Mapa Geral',
  'commons.overview-map': 'Mapa Geral',
  'commons.other-contact': 'Outro meio de contato',

  'commons.page-init-description':
    'Primeiro, adicione um(a) {item} no botão acima.',
  'commons.password': 'Senha',
  'commons.period': 'Período',
  'commons.phone': 'Telefone',
  'commons.photo': 'Foto',
  'commons.place': 'Rua',
  'commons.plan-lesson': 'Planejar aula',
  'commons.postalCode': 'CEP',
  'commons.prev': 'Anterior',
  'commons.priority': 'Prioridade',
  'commons.progress': 'Andamento',
  'commons.profile': 'Perfil',
  'commons.public': 'Público',

  'commons.quarter': 'Bimestre',

  'commons.recipient': 'Destinatário',
  'commons.recipients': 'Destinatários',
  'commons.recurrences': 'Recorrências',
  'commons.refresh': 'Atualizar',
  'commons.register': 'Cadastrar',
  'commons.registered': 'Matriculados',
  'commons.registration': 'Matrícula',
  'commons.release-feedback': 'Lançar Parecer',
  'commons.release-frequency': 'Lançar frequências',
  'commons.release-scores': 'Lançar Notas',
  'commons.released-lessons': 'Aulas Lançadas',
  'commons.report-type': 'Tipo de relatório',
  'commons.report': 'Relatório',
  'commons.reports': 'Relatórios',

  'commons.save': 'Salvar',
  'commons.saving': 'Salvando',
  'commons.school-year-finished': 'Ano letivo finalizado',
  'commons.score': 'Nota',
  'commons.scores': 'Notas',
  'commons.select': 'Selecionar',
  'commons.settings': 'Configurações',
  'commons.search': 'Buscar',
  'commons.search-instructor': 'Digite o nome do professor para buscar',
  'commons.search-recipients': 'Buscar destinatários',
  'commons.search-student': 'Digite o nome ou a matrícula do aluno para buscar',
  'commons.searchForPages': 'Pesquisa de páginas',
  'commons.semester': 'Semestre',
  'commons.show-infos': 'Exibir Informações',
  'commons.show-performance-date': 'Data de emissão do boletim',
  'commons.school-diary': 'Diário Escolar',
  'commons.situation': 'Situação',
  'commons.skip': 'Pular',
  'commons.start': 'Início',
  'commons.start-date': 'Data inicial',
  'commons.status': 'Status',
  'commons.student': 'Aluno',
  'commons.students': 'Alunos',
  'commons.subject': 'Assunto',
  'commons.submit': 'Enviar',
  'commons.sub-component': 'Sub Componentes',
  'commons.successMessage': 'Os dados foram criados com sucesso',
  'commons.summary': 'Resumo',
  'commons.suggestion': 'Sugestão',

  'commons.teaching': 'Ensino',
  'commons.teachingType': 'Tipo de Ensino',
  'commons.teams': 'Turmas',
  'commons.time': 'Horário',
  'commons.times': 'Horários',
  'commons.title': 'Título',
  'commons.to': 'Até',
  'commons.today': 'Hoje',
  'commons.toEdit': 'Editar',
  'commons.total': 'Total',
  'commons.trainingType': 'Tipo de Formação',
  'commons.training-type': 'Tipo de Formação',
  'commons.trainingTypes': 'Tipos de Formação',
  'commons.trimester': 'Trimestre',
  'commons.type': 'Tipo',

  'commons.updateSuccessMessage': 'Os dados foram atualizados com sucesso',
  'commons.user': 'Usuário',
  'commons.userName': 'Usuário',

  'commons.weCouldNotFindInformationInDatabase':
    'Não conseguimos encontrar nenhuma informação em nossa base de dados.',
  'commons.weDidNotFindDataFor': 'Não encontramos dados para',
  'commons.week': 'Semana',
  'commons.weekday': 'Dia da Semana',

  'commons.year': 'Ano',
  'commons.yes': 'Sim',
  'commons.yearly-mark': 'Nota Anual',
  'commons.yearly-average': 'Média Anual',

  'commons.vacancies': 'Vagas',
};

export default commonsPtMessages;
